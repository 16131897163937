<template lang="pug">
  v-container
    v-row
      v-col
        v-card(
          outlined
        )
          v-card-title
            v-layout(
              flex
            )
              v-text-field.flex-shrink-1.flex-grow-0(
                v-model.trim="search"
                label="Search Users"
                single-line
                prepend-inner-icon="mdi-magnify"
                hide-details
                filled
                :loading="(search !== null || search !== '') && areUsersPending"
                rounded
                dense
              )

          v-data-table.clickable(
            :headers="headers"
            :loading="areUsersPending"
            no-data-text="No Users Found"
            :options.sync="options"
            :server-items-length="haveUsersLoaded ? paginationData.default.mostRecent.total : 0"
            :items="users"
            @click:row="(item) => item.username ? $router.push({name: 'user', params: { userId: item.id }}) : $router.push({name: 'shop', params: { shopId: item.id }})"
            :footer-props="{ itemsPerPageOptions: [5,10,15] }"
          )</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'Users',
  setup (props, context) {
    const { User } = context.root.$FeathersVuex.api

    // Setups up a reference that the search can v-model
    const search = ref(null)

    // Basic starter datatable attributes
    const options = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['createdAt'],
      sortDesc: [true]
    })

    // Compute the sort based on various datatable properties
    const sortBy = computed(() => {
      var obj = {}
      if (options.value.sortBy && options.value.sortBy.length) {
        obj[options.value.sortBy[0]] = options.value.sortDesc[0] ? -1 : 1
      }
      return obj
    })

    const limit = computed(() => {
      if (options.value.itemsPerPage !== -1) {
        return options.value.itemsPerPage
      } else {
        return 999
      }
    })

    const query = computed(() => {
      // Construct the base query
      const query = {
        $limit: limit.value,
        $skip: options.value.itemsPerPage * (options.value.page - 1),
        $sort: sortBy.value
      }

      return query
    })

    const params = computed(() => {
      const params = { ...query.value }

      if (search.value !== null && search.value !== '') {
        params.username = {
          $regex: '^' + search.value,
          $options: 'gi'
        }
      }

      return { query: params }
    })

    const fetchParams = computed(() => {
      const fetchParams = { ...query.value }

      if (search.value !== null && search.value !== '') {
        fetchParams.username = {
          $iRegexp: '^' + search.value
        }
      }

      return { query: { ...fetchParams, $eager: 'shop', $mergeEager: 'region' } }
    })

    // Do the actual querying, be sure to remember to paginate
    const { items: users, isPending: areUsersPending, paginationData, haveLoaded: haveUsersLoaded } = useFind({
      model: User,
      params,
      fetchParams,
      paginate: true
    })
    return {
      search,
      options,
      sortBy,
      limit,
      users,
      query,
      params,
      fetchParams,
      areUsersPending,
      haveUsersLoaded,
      paginationData
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    headers () {
      const headers = []

      headers.push({
        text: 'User',
        value: 'username'
      })
      if (this.user.isAdmin || this.user.isRegionAdmin) {
        headers.push({
          text: 'Shop',
          value: 'shop.name'
        })
      }
      if (this.user.isAdmin || this.user.isRegionAdmin) {
        headers.push({
          text: 'Region',
          value: 'region.name'
        })
      }

      return headers
    }
  },
  data: () => ({

  })
}
</script>
